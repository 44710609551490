import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ReportsComponent } from './components/reports/reports.component';
import { PrintReportComponent } from './components/print-report/print-report.component';

import { AuthorizationGuard } from '../app/guards/authorization.guard';
import { UploadsComponent } from './components/uploads/uploads.component';
import { FileManagerComponent } from './components/file-manager/file-manager.component';
import { BinnacleComponent } from './components/binnacle/binnacle.component';
import { UsersComponent } from './components/users/users.component';
import { UsersAssignsComponent } from './components/users-assigns/users-assigns.component';
import { LoginComponent } from './components/login/login.component';

import { BplogsComponent } from './components/interest/search/bplog/bplog.component';
import { CompanylogsComponent } from './components/interest/search/companylog/companylog.component';
import { SupplierlogsComponent } from './components/interest/search/supplierlog/supplierlog.component';
import { AlertComponent } from './components/interest/configuration/alert/alert.component';
import { AssignAlertToCompanyComponent } from './components/interest/configuration/assing/assing.component';
import { CompanyComponent } from './components/interest/configuration/company/company.component';
import { ManagerComponent } from './components/interest/configuration/manager/manager.component';
import { ManagerByCompanyComponent } from './components/interest/configuration/manager-by-company/manager-by-company.component';
import { AlertEmailComponent } from './components/interest/configuration/alert-email/alert-email.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home/' },
  { path: 'home/:UserId', component: HomeComponent,
  canActivate: [AuthorizationGuard]},
  {
    path: 'uploads',
    component: UploadsComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'file-manager',
    component: FileManagerComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'binnacle',
    component: BinnacleComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'generalcompany',
    component: CompanyComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'user-assigns',
    component: UsersAssignsComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'reports-crud',
    component: ReportsComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'print-report/:reportId',
    component: PrintReportComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  //#region Intereses
  {
    path: 'search_bplog',
    component: BplogsComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'search_companylog',
    component: CompanylogsComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'search_supplierlog',
    component: SupplierlogsComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'configint_emailalert',
    component: AlertEmailComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'configint_alert',
    component: AlertComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'configint_assignalert',
    component: AssignAlertToCompanyComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'configint_company',
    component: CompanyComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'configint_manager',
    component: ManagerComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'configint_managerbycompany',
    component: ManagerByCompanyComponent,
    canActivate: [AuthorizationGuard]
  },
  //#endregion
  
  { path: '**', pathMatch: 'full', redirectTo: 'home/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
