<div class="margin-bottom">
    <div class="header text-right no-pm-0 mt-4">
        <div class="row no-pm-0 header-bot-line">
            <h3 class='no-pm-0 title-font'>Asignación de compañía a un gestor</h3>
        </div>
    </div>
    <div class="row justify-content-md-center pt-4">
        <div class="col-sm-12 col-12">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="managerByCompanyForm">
                        <div class="row row-space">
                            <div class="col-md-6">
                                <label for="company">Gestores</label>
                                <div class="form-group">
                                    <select class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                        formControlName="selectedManager" id="selectedManager" (change)="getCompanysByManager($event)"
                                        [ngClass]="{ 'is-invalid': getManagerByCompanyForm.selectedManager.errors && (getManagerByCompanyForm.selectedManager.touched || getManagerByCompanyForm.selectedManager.untouched) }">
                                        <option value="">--Gestor--</option>
                                        <option *ngFor="let manger of managerList" [value]="manger.Id">
                                            {{manger.ManagerName}}
                                        </option>
                                    </select>
                                    <!-- error block -->
                                    <div class="invalid-feedback"
                                        *ngIf="getManagerByCompanyForm.selectedManager.errors">
                                        <sup>*</sup>Por favor seleccione un gestor
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-12">
                            <div class="row row-space">
                                <div class="card col-sm-5 col-5 mt-5">
                                    <div style="background-color: rgb(197, 197, 197);">
                                    </div>
                                    <div class="card-body">
                                        <div class="row mt-3">
                                            <div class="col-sm-12 tableFixHead">
                                                <table class="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Compañías asignadas</th>
                                                            <th scope="col">Eliminar</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let company of managerCompanyList">
                                                            <td>{{ company.CompanyName }}</td>
                                                            <td><a class="btn btn-danger" aria-label="Eliminar"
                                                                (click)="removeCompanyFromManagerCompanyList(company)">
                                                                <i class="fa fa-trash-o" aria-hidden="true"></i>
                                                              </a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 col-2 mt-2 d-flex align-items-center justify-content-center">
                                    <!-- <i class="fa fa-arrow-circle-o-left fa-3x" aria-hidden="true"></i> -->
                                </div>
                                <div class="card col-sm-5 col-5 mt-5">
                                    <div style="background-color: rgb(197, 197, 197);">
                                    </div>
                                    <div class="card-body">
                                        <div class="row mt-3">
                                            <div class="col-sm-12 tableFixHead">
                                                <table class="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Compañías disponibles</th>
                                                            <th scope="col">Agregar</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let company of availabeCompanyList">
                                                            <td>{{ company.CompanyName }}</td>
                                                            <td><a class="btn btn-success" aria-label="Agregar"
                                                                (click)="pushCompanyToManagerCompanyList(company)">
                                                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                                                              </a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row row-space pt-2">
                            <div class="col-md-3">
                                <button class="form-control"
                                    [disabled]="!managerByCompanyForm.valid"
                                    (click)="createManagerByCompanyForm()">
                                    <i class="fa fa-plus"></i>&nbsp;Crear asignación</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
