import { Component, OnInit } from '@angular/core';
import { IMaterialManager, IUser } from '../../models/index';
import { FileManagerService } from '../../services/file-manager.service';
import { AlertService, BlockUIHandlerService } from 'src/app/common/services.index';
import { ALERTS } from 'src/app/common/enums';
@Component({
  selector: 'app-uploads',
  templateUrl: './uploads.component.html',
  styleUrls: ['./uploads.component.css']
})
export class UploadsComponent implements OnInit {
  files: any[] = [];
  
  constructor(
    private fileManagerService: FileManagerService
    ,private alertService: AlertService
    ,private blockUIHandler: BlockUIHandlerService
  ) { }

  ngOnInit(): void {
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  UploadDocuments(): void {

    let materials = this.files.map(x => x as File);
    let materialManager = {} as IMaterialManager;
    const USER = {
      Email: 'eaguilar@clavisco.com',
      UserName: 'eaguilar',
      Id: 3
    } as IUser;
    materialManager.Files = materials;
    materialManager.User = USER;

    let formData = new FormData();
    materials.forEach((x, i) => {
      formData.append(`file_${i}`, x);
    });

    materialManager.FilesAmout = materials.length;
    this.blockUIHandler.Start(1, `Guardando archivos`);
    formData.append(`MaterialManager`, JSON.stringify(materialManager));
    this.fileManagerService.SaveDocuments(formData).subscribe(next => {
      this.blockUIHandler.Stop();
      if (next.Result) {
        console.log(next)
        this.alertService.ShowSmallCornerAlert( materials.length > 1 ? `Documentos guardados`: `Documento guardado`, ALERTS.success);
      }
      else {
        this.alertService.ShowBigCenteredAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.error);
      }
    }, error => {
      this.blockUIHandler.Stop();
      console.log(error);
      this.alertService.ShowBigCenteredAlert(`Error: ${error}`, ALERTS.error);
    });
  }
}
