import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import {
  FormBuilder,
  FormGroup,
  NgbModal,
  Validators,
} from 'src/app/common/angular.index';
import { ALERTS } from 'src/app/common/enums';
import {
  AlertService,
  QuickAccessService,
  SessionService,
} from 'src/app/common/services.index';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  accessList: any[];
  quickAccessForm: FormGroup;

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private alertService: AlertService,
    private quickAccessService: QuickAccessService
  ) {}

  ngOnInit(): void {
    this.quickAccessForm = this.formBuilder.group({
      Name: ['', Validators.required],
      URL: ['', Validators.required],
    });

    let userId = this.route.snapshot.paramMap.get('UserId');
    // this.validateSession(userId);
    // this.getQuickAccess();
  }

  onClickQuickAccessModal(modal: any) {
    this.modalService.open(modal, {
      centered: true,
      size: 'md',
      backdrop: true,
    });
  }

  onClickSaveChanges() {
    if (!this.accessList) this.accessList = [];
    let quickAccess = {
      Name: this.quickAccessForm.get('Name').value,
      URL: this.quickAccessForm.get('URL').value,
      Domain: 'www.google.com',
    };

    this.accessList.push(quickAccess);
    this.dismissModal(true);
    this.quickAccessService.setQuickAccessList(this.accessList);
  }

  onClickDeleteAccess(index: number) {
    this.accessList.splice(index, 1);
    this.quickAccessService.setQuickAccessList(this.accessList);
  }

  dismissModal(result: boolean) {
    this.resetFormInitialState();
    this.modalService.dismissAll(result);
  }

  resetFormInitialState() {
    this.quickAccessForm.reset({
      Name: '',
      URL: '',
    });
  }

  validateSession(userId: string) {
    if (this.sessionService.session.Active) return;
    //#pr
    this.sessionService
      .logIn(userId)
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.access_token) {
            this.sessionService.setCurrentSession(response);
          } else {
            // this.alertService.ShowBigCenteredAlert(
            //   'El usuario no se encuentra registrado en la aplicación',
            //   ALERTS.error
            // );
          }
        },
        (err) => {
          console.log(err);
          // this.alertService.ShowBigCenteredAlert(
          //   'El usuario no se encuentra registrado en la aplicación',
          //   ALERTS.error
          // );
        }
      );
  }

  getQuickAccess() {
    this.accessList = this.quickAccessService.getQuickAccess();
  }
}
