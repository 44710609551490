import { Component, OnInit, Input } from '@angular/core';

// SERVICES
import {
  SideBarService,
  SectionService,
  SessionService,
} from '../../../common/services.index';
import { AuthenticationService } from 'src/app/services';
import { IMenuOption } from 'src/app/models';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppConstants } from 'src/app/common/constants'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  sidebarState: boolean;
  menu: IMenuOption[];
  currentPath = 'Inicio';
  @Input() currentUser: string;
  constructor(
    private sidebarService: SideBarService
    ,public sectionService: SectionService
    ,public sessionService: SessionService
    ,private authenticationService: AuthenticationService
    ,private router: Router
  ) {
    this.menu =  AppConstants.GetMenu;
    this.sidebarState = true;
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.sidebarService.currentPath.subscribe(next => this.currentPath = next);
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
    });
  }

  ngOnInit(): void {}

  toggleSidebar() {
    this.sidebarState = !this.sidebarState;
    this.sidebarService.toggler.next(this.sidebarState);
  }

  menuMapper(menuOptions: IMenuOption[], target: string): void {
    menuOptions.forEach(x => x.Selected = false);

    menuOptions.forEach(x => {
      if (x.Children.length > 0) {
        x.Children.forEach(y => {
          if (y.Route === ('/' + target)) this.currentPath = y.Name;
        });
      }
      else {
        if (x.Route === ('/' +target)) this.currentPath = x.Name;
      }
    });
  }
}
