import { Injectable, Input } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
// Models
import { ALERTS, Menu, AppConstants } from '../common/models.index';
// Services
import { AlertService, SessionService, SideBarService } from '../common/services.index';
import { AuthenticationService, StorageService, UserService } from '../services';
import { IMenuOption } from '../models';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  menu: IMenuOption[];
  keys: number[];
  @Input() currentUser: string;
  constructor(
    private sessionService: SessionService,
    private alertService: AlertService
    , private authenticationService: AuthenticationService
    , private router: Router
    , private storageService: StorageService
    , private sidebarService: SideBarService
    , private userService: UserService
  ) {
    this.menu = AppConstants.GetMenu;
    this.authenticationService.currentUser.subscribe(next => this.currentUser = next);
    // this.loadKeys();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.currentUser || this.router.navigate(['/login']);

    const TARGET = route.url[0].path;

    const MOBJECT = JSON.parse(this.storageService.GetCurrentSession());

    const EXPIRATION_DATE = new Date(MOBJECT['.expires']);

    if (!EXPIRATION_DATE) {
      this.FlushSession();
      return false;
    }

    const CURRENT_DATE = new Date();

    const TOKEN_LEFT_TIME = EXPIRATION_DATE.getTime() - CURRENT_DATE.getTime();

    if (TOKEN_LEFT_TIME <= 0) {
      this.FlushSession();
      return false;
    }

    const MENU_OPTION = this.FindPath(TARGET);

    if (!MENU_OPTION) {
      this.alertService.ShowSmallCornerAlert(`No tiene acceso a esta página`, ALERTS.warning);
      this.sidebarService.currentPath.next('Inicio');
      this.router.navigateByUrl('home');
      return false;
    }

    this.sidebarService.currentPath.next(MENU_OPTION.Name);

    if (MENU_OPTION.RequiredPerm === 'V_Home') return true;
    const PERMISSION = this.storageService.GetPermissions().find(x => x.Code === MENU_OPTION.RequiredPerm);

    if (!PERMISSION) {
      this.alertService.ShowSmallCornerAlert(`No tiene acceso a esta página`, ALERTS.warning);
      this.sidebarService.currentPath.next('Inicio');
      this.router.navigateByUrl('home');
      return false;
    }

    return true;
  }



  loadKeys() {
    if (!this.sessionService.session.Active) return;

    this.keys = this.sessionService.session.Keys.split(',');
  }

  FlushSession(): void {
    this.storageService.FlushToken();
    this.userService.FlushSession().subscribe(next => {
      // No se implementa porque simula una peticion con backendless
      console.log(next);
    }, error => {
      this.alertService.ShowSmallCornerAlert(error, ALERTS.error);
    }).unsubscribe();
  }

  FindPath(_path: string): IMenuOption {
    _path = '/' + _path;
    for (let c = 0; c < this.menu.length; c++) {
      if (this.menu[c].Route == _path) {
        return this.menu[c];

      }
      for (let d = 0; d < this.menu[c].Children.length; d++) {
        if (this.menu[c].Children[d].Route == _path) {
          return this.menu[c].Children[d];
        }
        for (let e = 0; e < this.menu[c].Children[d].Children.length; e++) {
          if (this.menu[c].Children[d].Children[e].Route == _path) {
            return this.menu[c].Children[d].Children[e];
          }
        }
      }
    }
  }
}
