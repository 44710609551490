import { TransactLinesResponse } from './../models/responses/transact-lines.response';
import { Injectable } from '@angular/core';
// Angular common
import { HttpClient, HttpHeaders } from '../common/angular.index';
// Models
import {
  AppConstants,
  BaseResponse,
  PrintResponse,
  TransactsResponse,
} from '../common/models.index';
import { SessionService } from './session.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class TransactService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  getTransacts(transactType: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });
    return this.http.get<TransactsResponse>(
      `${environment.ApiUrl}api/Transact/GetTransacts?transactType=${transactType}`,
      { headers }
    );
  }

  postTransact(
    transactNumber: number,
    preliminary: boolean,
    transactDocuments: number[]
  ) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });
    return this.http.post<BaseResponse>(
      `${environment.ApiUrl}api/Transact/PostTransact?transactNumber=${transactNumber}&preliminary=${preliminary}`,
      transactDocuments,
      { headers }
    );
  }

  completeTransact(transactId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });
    return this.http.post<BaseResponse>(
      `${environment.ApiUrl}api/Transact/CompleteTransact?transactId=${transactId}`,
      {},
      { headers }
    );
  }

  getTransactLines(transactId: number, preliminary: boolean) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });

    return this.http.get<TransactLinesResponse>(
      `${environment.ApiUrl}api/Transact/GetTransactLines?transactId=${transactId}&preliminary=${preliminary}`,
      { headers }
    );
  }

  printReport(numTramite: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });

    return this.http.get<PrintResponse>(
      `${environment.ApiUrl}api/Transact/PrintReport?numTramite=${numTramite}`,
      { headers }
    );
  }
}
