import { Injectable } from '@angular/core';

// Angular common
import { HttpClient, HttpHeaders } from '../common/angular.index';

// Models
import { GenericValuesResponse, AppConstants } from '../common/models.index';
import { SessionService } from './session.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ValuesService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  getValues(requestedValues: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });
    return this.http.get<GenericValuesResponse>(
      `${environment.ApiUrl}api/Values/GetValues?requestedValues=${requestedValues}`,
      { headers }
    );
  }
}
