import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IWarehousesResponse } from '../models/responses';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private httpClient: HttpClient) {}

  getFileExtension(file: File): string {
    return file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
  }

  isValidFile(file: File, validExtensions: string[]) {
    let fileExtension = this.getFileExtension(file);

    return validExtensions.some((x) => x === fileExtension);
  }

  private stringToArrayBuffer(toConvert: string) {
    let buf = new ArrayBuffer(toConvert.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != toConvert.length; ++i)
      view[i] = toConvert.charCodeAt(i) & 0xff;

    return buf;
  }

  // downloadPDF(base64File: string, fileName: string) {
  //   let report = new Blob([this.stringToArrayBuffer(atob(base64File))], {
  //     type: 'application/pdf;',
  //   });

  //   let link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(report);
  //   link.download = `${fileName}.pdf`;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  downloadFile(
    base64File: string,
    fileName: string,
    blobType: string,
    fileExtension: string
  ) {
    let report = new Blob([this.stringToArrayBuffer(atob(base64File))], {
      type: blobType,
    });

    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(report);
    link.download = `${fileName}.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  GetWarehouses(): Observable<IWarehousesResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/WareHouse/GetWarehouses`;

    return this.httpClient.get<IWarehousesResponse>(url, { headers });
  }

  ParseError(_error: any): string {
    let message = 'Error: ';

    message += _error.error && _error.error.ErrorInfo ? (_error.error?.ErrorInfo?.Message || '') : _error + '';

    return message;
  }

}
