import { Component, OnInit } from '@angular/core';
import { IUser } from 'src/app/models';
import { UserService, AlertService } from 'src/app/services';
import { BlockUIHandlerService } from 'src/app/common/services.index';
import { ALERTS } from 'src/app/common/enums';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  //VARBOX
  modalReference: NgbModalRef;
  users: IUser[];
  user: IUser;
  userForm: FormGroup;
  constructor(private userService: UserService
    ,private alertService: AlertService
    ,private blockUiService: BlockUIHandlerService
    ,private formBuilder: FormBuilder
    ,private modalService: NgbModal) { }

  ngOnInit(): void {
    this.InitVariables();
  }

  InitVariables(): void {
    let u = {} as  IUser;
    this.ResetUserForm();
    this.LoadData();
  }

  LoadData(): void {
    this.Get();
  }

  Store(): void {
    const USER = this.userForm.value as IUser;
    USER.Id === -1 ? this.CreateUser(USER): this.UpdateUser(USER);
  }

  RaiseUserModal(content, isUpdating: boolean = false): void {
    if (!isUpdating) this.ResetUserForm();
    this.modalReference = this.modalService.open(content, 
      {
        centered: true,
        backdrop: 'static'
      });
  }

  CloseModal(): void {
    this.modalReference.close();
  }

  ResetUserForm(): void {
    this.userForm = this.formBuilder.group({
      Id: [-1],
      Email: ['', Validators.required],
      Password: [''],
      UserName: ['', Validators.required],
      IsActive: [false, Validators.required]
    });
  }

  Get(): void {
    this.userService.Get().subscribe(next => {
      if (next.Result) {
        this.users = next.Users;
        this.alertService.ShowSmallCornerAlert(`Usuarios obtenidos`, ALERTS.success);
      }
      else {
        this.alertService.ShowSmallCornerAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.warning);
      }
    }, error => {
      console.log(error); // 0qIIzxgs4DZqSHxG4jtuQg==
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.warning);
    });
  }

  GetUser(_id: number, content: any): void {
    this.userService.GetUser(_id).subscribe(next => {
      if (next.Result) {
        this.user = next.User;
        this.userForm.patchValue({
          ...this.user
        });
        this.RaiseUserModal(content, true);
      }
      else {
        this.alertService.ShowSmallCornerAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.warning);
      }
    }, error => {
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.warning);
    });
  }

  CreateUser(_user: IUser): void {
    this.blockUiService.Start(1, `Creando usuario`);
    this.userService.Store(_user).subscribe(next => {
      this.blockUiService.Stop();
      if (next.Result) {
        this.CloseModal();
        this.alertService.ShowSmallCornerAlert(`Usuario creado`, ALERTS.success);
        this.Get();
      }
      else {
        this.alertService.ShowBigCenteredAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.error);
        console.log(next);
      }
    }, error => {
      this.blockUiService.Stop();
      this.alertService.ShowBigCenteredAlert(`Error: ${error}`, ALERTS.error);
    });
  }

  UpdateUser(_user: IUser): void {
    this.blockUiService.Start(1, `Actualizando usuario`);
    this.userService.Update(_user).subscribe(next => {
      this.blockUiService.Stop();
      if (next.Result) {
        this.CloseModal();
        this.alertService.ShowSmallCornerAlert(`Usuario actualizado`, ALERTS.success);
        this.Get();
      }
      else {
        this.alertService.ShowBigCenteredAlert(`Error: ${next.ErrorInfo.Message}`, ALERTS.error);
        console.log(next);
      }
    }, error => {
      this.blockUiService.Stop();
      this.alertService.ShowBigCenteredAlert(`Error: ${error}`, ALERTS.error);
    });
  }
}