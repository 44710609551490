import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { map } from 'rxjs/operators';
import { PermissionService } from './materials/permission.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  constructor(
    private http: HttpClient
    ,private storage: StorageService,
    private permissionService: PermissionService
    ,private storageService: StorageService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public deleteCurrentUserValue(): any {
    this.currentUserSubject.next(null);
  }

  login(username: string, password: string) {

    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    console.log('login2222')

    return this.http.post<any>(`${environment.ApiUrl}token`, body.toString(), { headers })
      .pipe(map(user => {
        if (user && user.access_token) {
          this.storage.setCurrentSession(user);
          this.currentUserSubject.next(user);

          this.permissionService.GetPermissionsByUser(+user.Id).subscribe(next => {
            if (next.Result) {
              this.storageService.SetPermissions(next.Permissions);
            }
            else {
            }
          }, error => {
            console.log(error)
          });
        }

        return user;
      }));
  }

  // thirdPartyLogin(username: string, password: string) {

  //   const body = new HttpParams()
  //     .set('grant_type', 'password')
  //     .set('username', username)
  //     .set('password', password);

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/x-www-form-urlencoded'
  //   });

  //   return this.http.post<any>(`${AppConstants.API_URL_INTEREST_RATE}token`, body.toString(), { headers })
  //     .pipe(map(user => {
  //       if (user && user.access_token) {
  //         this.storage.SetThirdPatyToken(user);
  //       }
  //       return user;
  //     }));
  // }
}
