import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

// Angular common
import { Router } from './common/angular.index';
// Services
import {
  SideBarService,
  SessionService,
  RptManagerService,
  BlockUIHandlerService,
} from './common/services.index';
// Models
import { Menu } from './common/models.index';
import { AuthenticationService } from './services';
import { IMenuOption } from './models';
import { AppConstants } from './common/constants'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  opened = false;
  currentSession: any;
  sidebarMode = 'push';
  menu: IMenuOption[];
  // menu = Menu;
  @Input() currentUser: string;
  constructor(
    private router: Router,
    private sidebarService: SideBarService,
    public sessionService: SessionService,
    private rptManagerService: RptManagerService,
    private blockUI: BlockUIHandlerService
    ,private authtenticationService: AuthenticationService
  ) {
    this.menu = AppConstants.GetMenu;
    this.sidebarService.toggler.subscribe((toggle) => (this.opened = toggle));
    this.validateSession();
    this.authtenticationService.currentUser.subscribe(x => this.currentUser = x);
  }
  ngOnInit(): void {
    this.opened = this.currentUser !== null
  }

  openedChange($event) {}

  onClickMenuOption(menuOption: IMenuOption) {
    this.menu.forEach(x => {
      x.Selected = false;
      if (x.Children.length > 0) {
        x.Children.forEach(y => y.Selected = false);
      }
    });

    if (menuOption.Name === 'Salir') {
      this.opened = false;
      this.sidebarService.currentPath.next('Inicio');
      this.sessionService.logOut();
    }
    else {
      menuOption.Selected = true;
      if (menuOption.Children.length === 0) {
        this.sidebarService.currentPath.next(menuOption.Name);
        this.router.navigateByUrl(menuOption.Route);
      }
    }

  }

  validateSession() {
    if (this.sessionService.anyActiveSession())
      this.sessionService.loadSessionFromLocalStorage();
  }

  GetApplicationReports(option: any) {
    if (!option.Children || option.Children.length === 0) {
      this.blockUI.Start(1);
      this.rptManagerService
        .GetReports()
        .pipe(first())
        .subscribe(
          (response) => {
            this.blockUI.taskFinished();
            if (response.Result) {
              response.Reports.forEach((x) => {
                option.Children.push({
                  Name: x.DisplayName,
                  Selected: false,
                  Path: 'print-report',
                  Action: {
                    ActionName: 'PrintReport',
                    Params: x.Id,
                  },
                  RequiredKey: '3',
                });
              });
            }
          },
          (err) => {
            this.blockUI.taskFinished();
            console.log(err);
          }
        );
    }
  }

  PrintReport(reportId: number) {
    this.router.navigateByUrl(`print-report/${reportId}`);
  }

  TriggerPath(_path: string) {
    this.router.navigate(['/', _path]);
  }
}
