<block-ui>
    <div class="margin-bottom">
        <div class="header text-right no-pm-0 mt-4">
            <div class="row no-pm-0 header-bot-line">
                <h3 class='no-pm-0 title-font'>Logs clientes</h3>
            </div>
        </div>
        <div class="row pt-4">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="searchBusinessPartnerForm">
                            <div class="row">
                                <div class="col-sm-3" style="margin-bottom: 0px;">
                                    <label for="id">Fecha inicial</label>
                                    <input type="date" name="startDate" class="form-control" id="startDate"
                                        formControlName="startDate">
                                </div>
                                <div class="col-3" style="margin-bottom: 0px;">
                                    <label for="id">Fecha final</label>
                                    <input type="date" name="endDate" class="form-control" id="endDate"
                                        formControlName="endDate">
                                </div>
                                <div class="col-3">
                                    <label for="company">Compañía</label>
                                    <div class="form-group" style="margin-bottom: 0px;">
                                        <select class="form-control" formControlName="companyId" id="companyId"
                                        [ngClass]="{ 'is-invalid': companyIdbp.errors && (companyIdbp.touched || companyIdbp.untouched) }"
                                            (change)="getBPListFromSAP()">
                                            <option value="">Seleccione una compañía</option>
                                            <option *ngFor="let comp of companyList" [value]="comp.Id">
                                                {{comp.CompanyName}}
                                            </option>                                            
                                        </select>
                                        <!-- error block -->
                                        <div class="invalid-feedback" *ngIf="companyIdbp.errors">
                                            <sup>*</sup>Por favor seleccione una compañía
                                         </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <label for="Status">Estado</label>
                                    <div class="form-group" style="margin-bottom: 0px;">
                                        <select class="form-control" formControlName="Status" id="Status">
                                            <option *ngFor="let status of statusLog" [value]="status.key">
                                                {{status.value}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-9">
                                    <div [formGroup]="BusinessPartnerFieldForm">
                                        <label for="id">Socio de negocio</label>
                                        <div class="flexContainer" style="margin-bottom: 0px;">
                                            <input type="text" name="CardCode" formControlName="CardCode"
                                                class="form-control inputField" id="CardCode" [ngbTypeahead]="searchBP"
                                                (focus)="focusSearchBP$.next($event.target.value)"
                                                (click)="clickSearchBP$.next($event.target.value)"
                                                #instanceSearchBP="ngbTypeahead">
                                            <button
                                                class="fa fa-share-square-o fa-2x center-ico transparent resend-icon"
                                                aria-hidden="true" [disabled]="!BusinessPartnerFieldForm.valid"
                                                (click)='open(content)' title="Enviar estado de cuenta"></button>
                                            <button class="fa fa-file-pdf-o fa-2x center-ico transparent pdf-icon"
                                                aria-hidden="true" [disabled]="!BusinessPartnerFieldForm.valid"
                                                (click)='viewDocument()' title="Ver estado de cuenta"></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <button class="btn btn-white btn-blue padding-top btn-width"
                                        (click)='getBusinessPartnerLogs()'
                                        [disabled]="!searchBusinessPartnerForm.valid"><i
                                            class="fa fa-search"></i>&nbsp;Buscar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12 tableFixHead table-sm">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Proveedor</th>
                            <th scope="col">Fecha registro</th>
                            <th scope="col">Correo</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Respuesta</th>
                            <th scope="col">Tipo envío</th>
                            <th scope="col">Usuario</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let Lines of BPInfoLog">
                            <td>{{Lines.CardCode}}</td>
                            <td>{{ Lines.CardName }}</td>
                            <td>{{ Lines.BpRegisterDate }}</td>
                            <td>{{ Lines.Email }}</td>
                            <ng-container [ngSwitch]="Lines.Status">
                                <td *ngSwitchCase="1">Pendiente</td>
                                <td *ngSwitchCase="2">Enviando</td>
                                <td *ngSwitchCase="3">Enviado</td>
                                <td *ngSwitchCase="4">Error</td>
                            </ng-container>
                            <td>{{ Lines.ResponseStatus }}</td>
                            <ng-container [ngSwitch]="Lines.SendType">
                                <td *ngSwitchCase="1">Automático</td>
                                <td *ngSwitchCase="2">Manual</td>
                            </ng-container>
                            <td>{{ Lines.User }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <ng-template #content let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Envío reporte por cliente</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <form>
                <div class="form-group">
                    <label for="dateOfBirth">Está seguro que desea enviar el documento por correo electrónico?</label>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Boton cancelar')">Cancelar</button>
            <button type="button" class="btn btn-outline-dark" (click)="sendManualReportBP(); modal.close()">Enviar</button>
        </div>
    </ng-template>
</block-ui>