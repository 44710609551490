import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// MODELOS
import { SapConnectionModel } from './../../../../models/index'

// RUTAS
// COMPONENTES
// SERVICIOS
import { AlertService, ConfigParamsService } from './../../../../services/index'
import { ALERTS } from 'src/app/common/models.index';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.css']
})
export class ManagerComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  public createManagerForm: FormGroup;
  public updateManagerForm: FormGroup;

  managerList: Array<any>; // obj almacena la lista de gestores
  managerInEdition: any; // obj almacena el gestor seleccionado
  sapConnList: Array<SapConnectionModel>; // obj que almacena conexiones sap
  emailManagerList: Array<any>; // obj almacena la lista de correos de los gestores
  userList: Array<any>; // obj almacena la lista de usuarios para asignar a gestores
  constructor(private fb: FormBuilder,
    private configParamsService: ConfigParamsService,
    private alertService: AlertService
  ) { }

  errorMessage: string; // variable para el mensaje en caso de error

  ngOnInit() {
    this.ngOnLoad();
  }

  ngOnLoad() {
    this.createManagerForm = this.fb.group({
      //userSelected: ['', Validators.required],
      managerName: ['', Validators.required],
      managerStatus: ['', Validators.required],
      managerEmail: ['', Validators.required],
      managerPassword: ['', Validators.required],
      managerHost: ['', Validators.required],
      managerPort: ['', Validators.required],
      managerSSL: [false],
    });

    this.updateManagerForm = this.fb.group({
      managerSelected: ['', Validators.required],
      managerName: ['', Validators.required],
      managerStatus: ['', Validators.required],
      managerEmail: ['', Validators.required],
      managerPassword: [''],
      managerHost: ['', Validators.required],
      managerPort: ['', Validators.required],
      managerSSL: [false],
    });

    this.getManagerList();
    //this.getEmailManagerList(); 
    //this.getUserList();
  }

  get createFormManager() {
    return this.createManagerForm.controls;
  }
  get updateFormManager() {
    return this.updateManagerForm.controls;
  }

  getManagerList() {
    this.blockUI.start('Buscando gestores');
    console.log('Buscando gestores');
    this.configParamsService.getManagerList()
      .subscribe((data: any) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.managerList = data.ManagerList;
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  // getUserList() {
  //   this.blockUI.start('Buscando usuarios');
  //   console.log('Buscando usuarios');
  //   this.configParamsService.getUserList()
  //     .subscribe((data: any) => {
  //       console.log(data);
  //       console.log(data.result);
  //       if (data.Result) {
  //         this.userList = data.AspNetUsersFromMisSecList;
  //         this.blockUI.stop();
  //       } else {
  //         this.blockUI.stop(); // Stop blocking
  //         this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
  //       }
  //     }, (error: any) => {
  //       console.log(error);
  //       this.blockUI.stop(); // Stop blocking
  //       this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
  //     });
  // }

  createManager() {
    this.blockUI.start('Creando gestor, un momento por favor.');
    console.log('Creando gestor');
    // let userId = this.createManagerForm.controls['userSelected'].value;
    // let emailUser = this.userList.filter(x => x.UserId == userId).map(y => y.UserEmail);
    // this.createManagerForm.controls['managerEmail'].setValue(emailUser[0]);
    this.configParamsService.createManager(this.createManagerForm)
      .subscribe((data: any) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Gestor creado correctamente`, ALERTS.success);
          this.createManagerForm.reset();
          this.getManagerList();
          //this.getUserList();
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  updateManager() {
    this.blockUI.start('Acutalizando el gestor, un momento por favor.');
    console.log('Acutalizando el gestor');
    this.configParamsService.updateManager(this.managerInEdition, this.updateManagerForm)
      .subscribe((data: any) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Gestor actualizado correctamente`, ALERTS.success);
          this.updateManagerForm.reset();
          this.getManagerList();
          //this.getUserList();
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  managerSelected($event) {
    console.log("CompanySelected");
    this.managerInEdition = undefined;
    this.managerInEdition = this.managerList.find(manager => manager.Id == $event.target.value);
    if (this.managerInEdition) {
      this.PatchAlertData(this.managerInEdition);
    } else {
      this.updateManagerForm.reset();
    }
  }

  PatchAlertData(managerInEdition: any) {
    console.log("CompanyPatch");
    this.updateManagerForm.reset({
      managerSelected: managerInEdition.Id,
      managerName: managerInEdition.ManagerName,
      managerStatus: managerInEdition.Active,
      managerEmail: managerInEdition.Email,
      //managerPassword: managerInEdition.Password,
      managerHost: managerInEdition.Host,
      managerPort: managerInEdition.Port,
      managerSSL: managerInEdition.SSL

    })
  }
}
