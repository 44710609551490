import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// MODELOS
import { SapConnectionModel } from './../../../../models/index'

// RUTAS
// COMPONENTES
// SERVICIOS
import { AlertService, ConfigParamsService } from './../../../../services/index'
import { ALERTS } from 'src/app/common/models.index';

@Component({
  selector: 'app-alert-email',
  templateUrl: './alert-email.component.html',
  styleUrls: ['./alert-email.component.css']
})
export class AlertEmailComponent implements OnInit {
  
  @BlockUI() blockUI: NgBlockUI;  
  public emailForm: FormGroup;


  EmailConfigList: Array<any>; // obj almacena la lista de gestores
  managerInEdition: any; // obj almacena el gestor seleccionado
  sapConnList: Array<SapConnectionModel>; // obj que almacena conexiones sap
  emailManagerList: Array<any>; // obj almacena la lista de correos de los gestores
  userList: Array<any>; // obj almacena la lista de usuarios para asignar a gestores
  constructor(private fb: FormBuilder,
    private configParamsService: ConfigParamsService,
    private alertService: AlertService
  ) { }

  errorMessage: string; // variable para el mensaje en caso de error

  ngOnInit(): void {
    this.ngOnLoad();
  }

  ngOnLoad() {
    this.emailForm = this.fb.group({
      userSelected: [''],
      managerEmail: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      managerStatus: ['', Validators.required],      
      managerPassword: [''],
      managerHost: ['', Validators.required],
      managerPort: ['', Validators.required],
      managerSSL: [false],
    });

    this.getEmailList();
    //this.getEmailManagerList();    
  }

  get emailFormManager() {
    return this.emailForm.controls;
  }

  getEmailList() {
    this.blockUI.start('Buscando correo');
    console.log('Buscando correo');
    this.configParamsService.getEmailList()
      .subscribe((data: any) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.EmailConfigList = data.EmailConfigList;          
          this.userSelected(null, this.EmailConfigList.find(x => x.ActiveMailsService).Id)
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }
  
  checkAction(){
    let userId = this.emailForm.controls['userSelected'].value;
    if(typeof userId!='undefined' && userId){
      this.updateEmail();
    }
    else{
      this.createEmail();
    }
  }

  createEmail() {
    this.blockUI.start('Agregando correo, un momento por favor.');
    console.log('Creando correo');
    // let userId = this.emailForm.controls['userSelected'].value;
    // let emailUser = this.userList.filter(x => x.UserId == userId).map(y => y.UserEmail);
    // this.emailForm.controls['managerEmail'].setValue(emailUser[0]);
    this.configParamsService.createEmail(this.emailForm)
      .subscribe((data: any) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Correo agregado correctamente`, ALERTS.success);
          this.emailForm.reset();
          this.getEmailList();
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }  

  updateEmail() {
    this.blockUI.start('Actualizando correo, un momento por favor.');
    console.log('Actualizando correo');
    // let userId = this.emailForm.controls['userSelected'].value;
    // let emailUser = this.userList.filter(x => x.UserId == userId).map(y => y.UserEmail);
    // this.emailForm.controls['managerEmail'].setValue(emailUser[0]);
    this.configParamsService.updateEmail(this.emailForm)
      .subscribe((data: any) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Correo actualizado correctamente`, ALERTS.success);
          this.emailForm.reset();
          this.getEmailList();
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }  

  userSelected($event, Id: number = null) {
    console.log("CompanySelected");
    this.managerInEdition = undefined;
    let objReceived = Id ?? $event.target.value;
    this.managerInEdition = this.EmailConfigList.find(manager => manager.Id == objReceived);
    if (this.managerInEdition) {
      this.PatchAlertData(this.managerInEdition);
    } else {      
      this.emailForm.reset({        
        managerStatus: ''  
      })
    }
  }

  PatchAlertData(managerInEdition: any) {
    console.log("CompanyPatch");
    this.emailForm.reset({
      userSelected: managerInEdition.Id,
      managerEmail: managerInEdition.Email,
      managerStatus: managerInEdition.ActiveMailsService, 
      //managerEmail: managerInEdition.Email,
      //managerPassword: managerInEdition.Password,
      managerHost: managerInEdition.Host,
      managerPort: managerInEdition.Port,
      managerSSL: managerInEdition.SSL

    })
  }

}
