import { Injectable } from '@angular/core';
import swal, { SweetAlertOptions } from 'sweetalert2';
import Swal from 'sweetalert2';

// MODELS
import { ALERTS } from '../common/models.index';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor() {}

  ShowBigCenteredAlert(message: string, alertId: number) {
    this.BigCenteredAlert(message, ALERTS[alertId].toString());
  }

  ShowSmallCornerAlert(message: string, alertId: number) {
    this.SmallCornerAlert(message, ALERTS[alertId].toString());
  }

  ConfirmationAlert(titulo: string, msg: string, confirmButtonText: string) {
    let settings: SweetAlertOptions = {
      title: titulo,
      text: msg,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      cancelButtonColor: '#dc3545',
      confirmButtonText: confirmButtonText,
      cancelButtonText: 'Cancelar',
    };

    return Swal.fire(settings).then((result) => {
      return result.isConfirmed;
    });
  }

  private SmallCornerAlert(msn: any, tipo: any) {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000
    });

    toast.fire({
      icon: tipo,
      text: msn,
    });
  }

  private BigCenteredAlert(msn: any, tipo: any) {
    swal.fire({
      icon: tipo,
      text: msn,
      confirmButtonText: 'Continuar'
    });
  }

  //#region Intereses
   infoAlert(message: string) {
    let settings: SweetAlertOptions = {
      title: 'Información',
      text: message,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      confirmButtonText: 'Continuar'
    };

    return Swal.fire(settings).then((result) => {
      return result.isConfirmed;
    });
  }

   successAlert(message: string) {
    let settings: SweetAlertOptions = {
      title: 'Exitoso',
      text: message,
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      confirmButtonText: 'Continuar',
    };
  }

  errorAlert(message: string) {
    let settings: SweetAlertOptions = {
      title: 'Error',
      text: message,
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#007bff',
      confirmButtonText: 'Continuar',
    };
  }
//#endregion
}
