import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Observable, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, min } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbTypeahead, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

// MODELOS
import {
  BPInfoLogResponse,
  BusinessPartnerInfoResponse, ManualSendReportBPResponse, GetPdfReportResponse, CompaniesResponse, Company, SearchBusinessPartnerLogModel,
  BPInfoLog
} from 'src/app/models'

// RUTAS
// COMPONENTES
// SERVICIOS
import { AlertService, SearchService, ManualSendService, ConfigParamsService, StorageService } from 'src/app/services/'
import { ALERTS } from 'src/app/common/models.index';
import { CompanyService } from 'src/app/services/company.service';
import { CompaniaResponse } from 'src/app/models/responses';

@Component({
  selector: 'app-bplog',
  templateUrl: './bplog.component.html',
  styleUrls: ['./bplog.component.css']
})
export class BplogsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public searchBusinessPartnerForm: FormGroup;
  public BusinessPartnerFieldForm: FormGroup;

  infoSearchBPReport: SearchBusinessPartnerLogModel; // obj almacena la info para busqueda reporte por proveedor
  BPInfoLog: Array<BPInfoLog> = []; // obj almacena la info de logs consultada
  BpNameList: string[] = []; // almacena la lista de los proveedores traidos de sap
  companyList: Array<Company>; // almacena lista de compañias que se utiliza para cargar dropdown

  statusLog: { key: string, value: string }[] = [
    { key: '0', value: 'Todos' },
    { key: '1', value: 'Pendiente' },
    { key: '2', value: 'Enviando' },
    { key: '3', value: 'Enviado' },
    { key: '4', value: 'Error' }
  ];

  errorMessage: string; // variable para el mensaje en caso de error

  @ViewChild('instanceSearchBP', { static: true }) instanceSearchBP: NgbTypeahead;
  focusSearchBP$ = new Subject<string>();
  clickSearchBP$ = new Subject<string>();
  Today: any;
  date: Date = new Date(); // fecha actual
  closeResult = '';

  constructor(private fb: FormBuilder,
    private searchService: SearchService,
    private alertService: AlertService,
    private ManualSendService: ManualSendService,
    private configParamsService: ConfigParamsService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.ngLoad();
  }

  ngLoad() {
    this.Today = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;

    this.searchBusinessPartnerForm = this.fb.group({
      startDate: [this.Today, Validators.required],
      endDate: [this.Today, Validators.required],
      companyId: ['', [Validators.required]],
      CardCode: [''],
      Status: [this.statusLog[0].key, Validators.required]
    });

    this.BusinessPartnerFieldForm = this.fb.group({
      CardCode: ['', Validators.required]
    });

    this.getCompaniesList();
  }

  get companyIdbp() {
    return this.searchBusinessPartnerForm.get('companyId');
  }
  // Typeahead
  searchBP = (text$: Observable<string>) => {
    console.log('TypeAhead');
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.clickSearchBP$.pipe(filter(() => !this.instanceSearchBP.isPopupOpen()));
    const inputFocus$ = this.focusSearchBP$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.BpNameList
        : this.BpNameList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  getBPListFromSAP() {
    if ( Number(this.searchBusinessPartnerForm.controls['companyId'].value) > 0) {
      this.BpNameList = [];
      this.blockUI.start('Obteniendo lista de proveedores.');
      console.log('Obteniendo lista de proveedores');
      this.searchService.getBusinessPartnerFromSap(this.searchBusinessPartnerForm, 'C')
        .subscribe((data: BusinessPartnerInfoResponse) => {
          console.log(data);
          console.log(data.Result);
          if (data.Result) {
            this.BpNameList = data.BusinessPartnerInfoList.map((x) => x.FullName);;
            this.blockUI.stop();

          } else {
            this.blockUI.stop(); // Stop blocking
            this.errorMessage = data.ErrorInfo.Message;
            this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
          }
        }, (error: any) => {
          console.log(error);
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error);
        });
    }
    else{
      this.BpNameList = [];
    }
  }

  getCompaniesList() {
    this.blockUI.start('Buscando compañías');
    this.companyService.GetCompaniesByUser(this.storageService.GetUser())
      .subscribe((data: CompaniaResponse) => {
        if (data.Result) {
          this.companyList = data.CompaniesList;
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.errorMessage = data.ErrorInfo.Message;
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error);
      });
  }

  getBusinessPartnerLogs() {
    this.BPInfoLog = [];
    this.blockUI.start('Obteniendo reportes de proveedores.');
    console.log('Obteniendo logs de proveedores');
    this.searchService.getBusinessPartnerLogs(this.searchBusinessPartnerForm)
      .subscribe((data: BPInfoLogResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.BPInfoLog = data.BPInfoLogList;
          this.blockUI.stop();

        } else {
          this.blockUI.stop(); // Stop blocking
          this.errorMessage = data.ErrorInfo.Message;
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error);
      });
  }

  sendManualReportBP() {
    this.blockUI.start('Enviando reporte por cliente');
    console.log('Enviando reporte por cliente')
    this.ManualSendService.sendManualReportBP(this.searchBusinessPartnerForm, this.BusinessPartnerFieldForm)
      .subscribe((data: ManualSendReportBPResponse) => {
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Reporte enviado`, ALERTS.success);
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.errorMessage = data.ErrorInfo.Message;
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
        this.getBusinessPartnerLogs();
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error);
      });
  }

  // funcion para la visualizacion del archivo
  // recibe como parametro el id del documento a visualizar
  viewDocument() {
    this.blockUI.start('Generando reporte'); // Start blocking
    this.searchService.GetPdfReportBP(this.searchBusinessPartnerForm, this.BusinessPartnerFieldForm)
      .subscribe((data: GetPdfReportResponse) => {
        if (data.Result) {
          this.blockUI.stop(); // Stop blocking
          console.log(data);
          var fileBase64 = atob(data.PdfBase64);
          var length = fileBase64.length;
          var arrayBuffer = new ArrayBuffer(length);
          var uintArray = new Uint8Array(arrayBuffer);
          for (var i = 0; i < length; i++) {
            uintArray[i] = fileBase64.charCodeAt(i);
          }
          debugger
          var pdfFile = new Blob([uintArray], { type: 'application/pdf' });
          var fileUrl = URL.createObjectURL(pdfFile);
          window.open(`${fileUrl}`);
        }
        else {
          this.blockUI.stop(); // Stop blocking
          this.errorMessage = data.ErrorInfo.Message;
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        console.log('error');
        console.log(error);
        this.alertService.ShowBigCenteredAlert(`${error.Message}`, ALERTS.error);
      });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
