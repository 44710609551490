<block-ui>
  <div class="row">
    <div class="col-sm-8">
      <form [formGroup]="searchForm">
        <div class="form-row">
          <div class="form-group col-md-4">
            <label>Desde</label>
            <input type="date" formControlName="From" class="form-control form-control-sm" />
          </div>

          <div class="form-group col-md-4">
            <label>Hasta</label>
            <input type="date" formControlName="To" class="form-control form-control-sm" />
          </div>
          <div class="form-group col-md-4">
            <label for="">Usuario</label>
            <select formControlName="UserId" class="form-control form-control-sm">
              <option class="form-control" value="-1">Todos los usarios</option>
              <option *ngFor="let user of users" class="form-control" value="{{user.Id}}">{{user.Email}}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-4">
      <div class="row">
        <div class="col-sm-5 offset-sm-7">
          <label>&nbsp;</label>
          <button class="form-control form-control-sm btn-light shadow-sm btn-st" (click)="GetBinnacle()"><i
              class="fa fa-search"></i>
            Consultar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row" class="tableFixHead">
    <table class="table table-striped table-sm">
      <thead style="background-color: #172749; color: white;">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Usuario</th>
          <th scope="col">Fecha</th>
          <th scope="col">Evento</th>
          <th scope="col">Documento</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let record of binnacle; let i = index" class="p-0">
          <td scope="row">{{(i + 1)}}</td>
          <td>{{GetEmail(record.UserId)}}</td>
          <td>{{record.CreationDate}}</td>
          <td>{{record.Event}} <i *ngIf="record.Event.includes('ERROR') || (record.Event.includes('BORRAR') && (record.Comment && record.Comment !== ''))" class="fa fa-info-circle"
              aria-hidden="true" style="cursor: pointer;" (click)="PreviewError(record)" title="Previsualización del comentario"></i> </td>
          <td>{{record.Files}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <button type="button" style="display: none;" data-toggle="modal" data-target="#previewErrorModal"
    id="triggerPreviewErrorModal"></button>
  <div class="modal fade" id="previewErrorModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title" id="exampleModalLongTitle">Comentario</h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h6>{{currentRecord?.Comment}}</h6>
        </div>
      </div>
    </div>
  </div>
</block-ui>
