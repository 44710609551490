import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// MODELOS
import {
  AlertModelResponse, IAlert, AlertListResponse
} from './../../../../models/index'
import { 
  WeekDays
} from './../../../../common/constants'
// RUTAS
// COMPONENTES
// SERVICIOS
import { AlertService, ConfigParamsService } from './../../../../services/index'
import { ALERTS } from 'src/app/common/models.index';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public createAlertForm: FormGroup;

  weekDays: any = WeekDays;
  dayArray: Array<string> = [];
  alertList: Array<IAlert>; // obj almacena la lista de alertas activas
  alertInEdition: IAlert;

  //titulo de la vista
  title = 'Alertas';

  constructor(private fb: FormBuilder,
    private configParamsService: ConfigParamsService,
    private alertService: AlertService
  ) { }

  errorMessage: string; // variable para el mensaje en caso de error

  ngOnInit() {
    this.ngLoad();
  }

  ngLoad() {
    console.log(this.weekDays);
    this.createAlertForm = this.fb.group({
      alertName: ['', Validators.required],
      // planDays: ['', Validators.required],
      emailSubject: ['', Validators.required],
      emailBody: ['', Validators.required]
    });
    this.resetWeekDays();
    this.getAlerts();
  }

  get AlertForm() {
    return this.createAlertForm.controls;
  }

  resetWeekDays() {
    this.weekDays.forEach(day => day.checked = false);
    this.dayArray = [];
  }

  onChange(day: string, isChecked: boolean) {    
    if (isChecked) {
      this.dayArray.push(day);
      this.weekDays.filter(x => x.id == Number(day))
        .map(x => x.checked = true)
    } else {
      this.weekDays.filter(x => x.id == Number(day))
        .map(x => x.checked = false)
      const index = this.dayArray.indexOf(String(day));
      this.dayArray.splice(index, 1);
    }
    console.log(this.dayArray);
  }

  updateAlert() {
    this.blockUI.start('Creando alerta.');
    console.log('Creando alerta');
    this.alertInEdition.PlanDays = this.dayArray.map(x => x).join(",");
    this.configParamsService.updateAlert(this.alertInEdition, this.createAlertForm)
      .subscribe((data: AlertModelResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Alerta actualizada correctamente`, ALERTS.success);
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.errorMessage = data.ErrorInfo.Message;
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.error);          
          this.resetWeekDays();
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  getAlerts() {
    this.blockUI.start('Buscando alertas');
    console.log('Buscando alertas');
    this.configParamsService.getAlertList()
      .subscribe((data: AlertListResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertList = data.AlertList;         
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.error);           
          this.resetWeekDays();
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  AlertSelected($event) {
    console.log("AlertSelected");
    this.resetWeekDays();
    this.alertInEdition = undefined;
    this.alertInEdition = this.alertList.find(a => a.Id == $event.target.value);
    if (this.alertInEdition) {
      this.PatchAlertData(this.alertInEdition);
    } else {
      this.createAlertForm.reset();
    }
  }

  PatchAlertData(alert: IAlert) {
    const Days = alert.PlanDays
    if(Days != null){
      this.dayArray = Days != '' ? Days.split(',') : this.dayArray;
      this.dayArray.forEach(day =>
        this.weekDays.filter(x => x.id == Number(day))
          .map(x => x.checked = true)
      );      
    }
    this.createAlertForm.reset({
      alertName: alert.AlertName,
      planDays: alert.PlanDays,
      emailSubject: alert.EmailSubject,
      emailBody: alert.EmailBody
    })
    } 
    
}
