import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// Models
import {
  AppConstants,
  BaseResponse,
  Email,
  Parameter,
  ParametersResponse,
  PrintResponse,
  Report,
  ReportResponse,
  ReportUsersResponse,
} from '../common/models.index';

@Injectable({
  providedIn: 'root',
})
export class RptManagerService {
  constructor(private http: HttpClient) {}

  SaveReportFile(report: File) {
    const formData = new FormData();
    formData.append('report', report);

    return this.http.post<BaseResponse>(
      `pi/Reports/SaveReportFile?companyKey=1&appKey=1`,
      formData
    );
  }

  HandlePostOrPutReport(report: Report) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    report.ApplicationId = 1; //#VALORPRUEBA

    if (report.Id > 0) {
      return this.http.put<any>(
        `api/Reports/PutReport`,
        report,
        { headers }
      );
    } else {
      return this.http.post<any>(
        `api/Reports/PostReport`,
        report,
        { headers }
      );
    }
  }

  GetReports() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<ReportResponse>(
      `i/Reports/GetReports?companyKey=1&appKey=1`,
      { headers }
    );
  }

  GetParameters(reportId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<ParametersResponse>(
      `api/Parameter/GetParameters?reportId=${reportId}`,
      { headers }
    );
  }

  PrintReport(parameters: Parameter[], reportId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.post<PrintResponse>(
      `api/Reports/PrintReport?reportId=${reportId}`,
      parameters,
      { headers }
    );
  }

  SendEmail(emailInfo: Email, reportId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.post<BaseResponse>(
      `pi/Reports/SendEmail?reportId=${reportId}`,
      emailInfo,
      { headers }
    );
  }

  DownloadFile(reportId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<PrintResponse>(
      `api/Reports/DownloadFile?reportId=${reportId}`,
      { headers }
    );
  }

  GetReportUsers() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token.access_token}`
    });

    return this.http.get<ReportUsersResponse>(
      `api/ReportUser/GetReportUsers`,
      { headers }
    );
  }
}
