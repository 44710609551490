import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Form, FormGroup, Validators } from '@angular/forms';
import { DOCUMENT, formatDate } from '@angular/common';
import { ISearch, IUser } from 'src/app/models';
import { IBinnacle } from 'src/app/models/i-binnacle';
import { AlertService, BinnacleService, UserService } from 'src/app/services';
import { BlockUIHandlerService } from 'src/app/common/services.index';
import { ALERTS } from 'src/app/common/enums';

@Component({
  selector: 'app-binnacle',
  templateUrl: './binnacle.component.html',
  styleUrls: ['./binnacle.component.css']
})
export class BinnacleComponent implements OnInit {
  //VARBOX
  currentRecord: IBinnacle;
  users: IUser[];
  binnacle: IBinnacle[];
  mDate: string;
  hasAccess: boolean;
  searchForm: FormGroup;
  constructor(private formBuilder: FormBuilder
    ,private binnacleService: BinnacleService
    ,private alertService: AlertService
    ,private blockUIHandler: BlockUIHandlerService
    ,private userService: UserService
    ,@Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.InitVariables();
    this.LoadData();
  }

  InitVariables(): void {
    this.currentRecord = null;
    this.mDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.searchForm = this.formBuilder.group({
      From: [this.mDate, Validators.required],
      To: [this.mDate, Validators.required],
      UserId: ['-1']
    });
    this.binnacle = [];
  }

  LoadData(): void {
    this.CheckAccess();
    this.GetUsers();
  }

  CheckAccess(): void {
    // todo
    this.hasAccess = true;
  }

  GetBinnacle(): void {
    this.binnacle = [];
    this.blockUIHandler.Start(1);
    this.binnacleService.GetBinnacle(this.searchForm.value as ISearch).subscribe(next => {
      this.blockUIHandler.Stop();
      if (next.Result) {
        this.binnacle = next.Binnacle;
      }
      else {
        console.log(next);
        this.alertService.ShowSmallCornerAlert(`No hay registros ${next?.ErrorInfo?.Message || ''}`, ALERTS.info);
      }
    }, error => {
      this.blockUIHandler.Stop();
      this.alertService.ShowBigCenteredAlert(`Error: ${error}`, ALERTS.error);
      console.log(error);
    });
  }

  GetUsers(): void {
    this.blockUIHandler.Start(1);
    this.userService.Get().subscribe(next => {
      this.blockUIHandler.Stop();
      if (next.Result) {
        this.users = next.Users.filter(x => x.IsActive);
      }
      else {
        console.log(next);
        this.alertService.ShowSmallCornerAlert(`Error: ${next?.ErrorInfo?.Message || ''}`, ALERTS.error);
      }
    },error => {
      console.log(error);
      this.alertService.ShowSmallCornerAlert(`Error: ${error || ''}`, ALERTS.error);
      this.blockUIHandler.Stop();
    });
  }

  GetEmail(_id: number): string {
     const USER = this.users.find(x => x.Id === _id);
     if (USER) return USER.Email;

     return `ID_USER: ${_id}: NO ENCONTRADO`;
  }

  PreviewError(_record: IBinnacle) {
    this.currentRecord = _record;
    (<HTMLButtonElement> this.document.getElementById('triggerPreviewErrorModal')).click();
  }
}
