<div class="margin-bottom">
    <div class="header text-right no-pm-0 mt-4">
        <div class="row no-pm-0 header-bot-line">
            <h3 class='no-pm-0 title-font'>Mantenimiento de compañías</h3>
        </div>
    </div>
</div>
<div class="mt-2">
    <ngb-tabset class="pt-2" [destroyOnHide]="false">
        <ngb-tab title="Actualizar compañía">
            <ng-template ngbTabContent>
                <div class="row justify-content-md-center pt-3">
                    <div class="col-sm-12 col-12">
                        <div class="card">
                            <div class="card-body">
                                <form [formGroup]="updateCompanyForm">
                                    <div class="row row-space">
                                        <div class="col-md-4">
                                            <label for="id">Compañías</label>
                                            <div>
                                                <select
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    formControlName="companySelected" (change)="companySelected($event)"
                                                    [ngClass]="{ 'is-invalid': formUpdateCompany.companySelected.errors && (formUpdateCompany.companySelected.touched || formUpdateCompany.companySelected.untouched) }">
                                                    <option value="">Seleccione una compañía</option>
                                                    <option *ngFor="let company of companyList" [value]="company.Id">
                                                        {{company.CompanyName}}
                                                    </option>
                                                </select>
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="formUpdateCompany.sapConnectionId.errors">
                                                    <sup>*</sup>Por favor seleccione una conexión
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label for="company">Conexión SAP</label>
                                            <div class="form-group">
                                                <select
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    formControlName="sapConnectionId" id="sapConnectionId"
                                                    [ngClass]="{ 'is-invalid': formUpdateCompany.sapConnectionId.errors && (formUpdateCompany.sapConnectionId.touched || formUpdateCompany.sapConnectionId.untouched) }">
                                                    <option *ngFor="let conn of sapConnList" [value]="conn.Id">
                                                        {{conn.Id}}
                                                    </option>
                                                </select>
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="formUpdateCompany.sapConnectionId.errors">
                                                    <sup>*</sup>Por favor seleccione una conexión
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 justify-content-md-center">
                                            <label for="Status">Estado de la compañía</label>
                                            <div class="form-group">
                                                <select
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    formControlName="active" id="active"
                                                    [ngClass]="{ 'is-invalid': formUpdateCompany.active.errors && (formUpdateCompany.active.touched || formUpdateCompany.active.untouched) }">
                                                    <option value="true">Activa</option>
                                                    <option value="false">Inactiva</option>
                                                </select>
                                                <!-- error block -->
                                                <div class="invalid-feedback" *ngIf="formUpdateCompany.active.errors">
                                                    <sup>*</sup>Por favor seleccione el estado de la compañía
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-space">
                                        <div class="col-6">
                                            <label for="id">Código base de datos</label>
                                            <input type="text" name="dbCode"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="dbCode" formControlName="dbCode"
                                                [ngClass]="{ 'is-invalid': formUpdateCompany.dbCode.errors && (formUpdateCompany.dbCode.touched || formUpdateCompany.dbCode.untouched) }">
                                            <!-- error block -->
                                            <div class="invalid-feedback" *ngIf="formUpdateCompany.dbCode.errors">
                                                <sup>*</sup>Por favor digite el código base de datos
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <label for="id">Nombre de la compañía</label>
                                            <input type="text" name="companyName"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="companyName" formControlName="companyName"
                                                [ngClass]="{ 'is-invalid': formUpdateCompany.companyName.errors && (formUpdateCompany.companyName.touched || formUpdateCompany.companyName.untouched) }">
                                            <!-- error block -->
                                            <div class="invalid-feedback" *ngIf="formUpdateCompany.companyName.errors">
                                                <sup>*</sup>Por favor digite el nombre de la compañía
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-space">

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="id">Nombre del reporte por compañía</label> <br />
                                                <div class="input-group input-group-sm">
                                                    <input style="display: none" type="file"
                                                        (change)="onFileSelected($event,1,2,3)" #inputCompanyReport
                                                        accept=".rpt" />
                                                    <input type="text" class="form-control form-control-sm"
                                                        aria-describedby="btnCompanyReport"
                                                        formControlName="companyReportName" readonly />
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-sm btn-light"
                                                            id="btnCompanyReport" title="Cargar archivo"
                                                            (click)="inputCompanyReport.click()">
                                                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light"
                                                            id="btnCompanyReportDown" title="Descargar archivo"
                                                            (click)="fn_downloadFile(3)">
                                                            <i class="fa fa-download" aria-hidden="true"></i>
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="id">Nombre del reporte por cliente</label> <br />
                                                <div class="input-group input-group-sm">
                                                    <input style="display: none" type="file"
                                                        (change)="onFileSelected($event,1,2,1)" #inputBPReport
                                                        accept=".rpt" />
                                                    <input type="text" class="form-control form-control-sm"
                                                        aria-describedby="btnBPReport"
                                                        formControlName="businessPartnerReportName" readonly />
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-sm btn-light"
                                                            id="btnBPReport" title="Cargar archivo"
                                                            (click)="inputBPReport.click()">
                                                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light"
                                                            id="btnCompanyReportDown" title="Descargar archivo"
                                                            (click)="fn_downloadFile(1)">
                                                            <i class="fa fa-download" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="id">Nombre del reporte proveedor</label> <br />
                                                <div class="input-group input-group-sm">
                                                    <input style="display: none" type="file"
                                                        (change)="onFileSelected($event,1,2,2)" #inputSuppReport
                                                        accept=".rpt" />
                                                    <input type="text" class="form-control form-control-sm"
                                                        aria-describedby="btnSuppReport"
                                                        formControlName="supplierReportName" readonly />
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-sm btn-light"
                                                            id="btnSuppReport" title="Cargar archivo"
                                                            (click)="inputSuppReport.click()">
                                                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                                                        </button>
                                                        <button type="button" class="btn btn-sm btn-light"
                                                            id="btnCompanyReportDown" title="Descargar archivo"
                                                            (click)="fn_downloadFile(2)">
                                                            <i class="fa fa-download" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row row-space">
                                        <div class="col-md-12">
                                            <label for="id">Correo a</label> <br />
                                            <textarea
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                name="emailTo" formControlName="emailTo" id="emailTo"
                                                [ngClass]="{ 'is-invalid': formUpdateCompany.emailTo.errors && (formUpdateCompany.emailTo.touched || formUpdateCompany.emailTo.untouched) }">
                                                                                    </textarea>
                                            <!-- error block -->
                                            <div class="invalid-feedback" *ngIf="formUpdateCompany.emailTo.errors">
                                                <sup>*</sup>Por favor digite un correo electrónico
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="id">Correo copia (separar con punto y coma (;) entre correos
                                                electrónicos)</label> <br />
                                            <textarea name="emailCc" formControlName="emailCc"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="emailCc">
                                                                                    </textarea>
                                            <!-- error block -->
                                            <div class="invalid-feedback" *ngIf="formUpdateCompany.companyName.errors">
                                                <sup>*</sup>Por favor digite el nombre de la compañía
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="id">Correo interno, envío reporte de clientes (separar con punto
                                                y coma (;) entre correos electrónicos)</label> <br />
                                            <textarea name="InternalEmailReport" formControlName="InternalEmailReport"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="InternalEmailReport">
                                                                                    </textarea>
                                            <!-- error block -->
                                            <div class="invalid-feedback" *ngIf="formUpdateCompany.companyName.errors">
                                                <sup>*</sup>Por favor digite el nombre de la compañía
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="id">Firma del correo</label> <br />
                                                <div class="input-group input-group-sm">
                                                    <input style="display: none" type="file"
                                                        (change)="onFileSelected($event,1,1,0)" #inputSearchReport
                                                        accept=".png" />
                                                    <input type="text" class="form-control form-control-sm"
                                                        aria-describedby="btnSearchReport" formControlName="Signature"
                                                        readonly />
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-sm btn-light"
                                                            id="btnSearchReport" title="Cargar archivo"
                                                            (click)="inputSearchReport.click()">
                                                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-space">
                                        <div class="col-md-3 pt-2">
                                            <button class="form-control truncate" (click)="updateCompany()"
                                                [disabled]="!updateCompanyForm.valid">
                                                <i class="fa fa-refresh"></i>&nbsp;Actualizar compañía</button>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>


                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="Crear compañía">
            <ng-template ngbTabContent>
                <div class="row justify-content-md-center pt-3">
                    <div class="col-sm-12 col-12">
                        <div class="card">
                            <div class="card-body">
                                <form [formGroup]="createCompanyForm">
                                    <div class="row row-space">
                                        <div class="col-6">
                                            <label for="id">Código base de datos</label>
                                            <input type="text" name="dbCode"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="dbCode" formControlName="dbCode"
                                                [ngClass]="{ 'is-invalid': formCreateCompany.dbCode.errors && (formCreateCompany.dbCode.touched || formCreateCompany.dbCode.untouched) }">
                                            <!-- error block -->
                                            <div class="invalid-feedback" *ngIf="formCreateCompany.dbCode.errors">
                                                <sup>*</sup>Por favor digite el nombre de la compañía
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <label for="id">Nombre de la compañía</label>
                                            <input type="text" name="companyName"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="companyName" formControlName="companyName"
                                                [ngClass]="{ 'is-invalid': formCreateCompany.companyName.errors && (formCreateCompany.companyName.touched || formCreateCompany.companyName.untouched) }">
                                            <!-- error block -->
                                            <div class="invalid-feedback" *ngIf="formCreateCompany.companyName.errors">
                                                <sup>*</sup>Por favor digite el nombre de la compañía
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-space">
                                        <div class="col-md-6">
                                            <label for="company">Conexión SAP</label>
                                            <div class="form-group">
                                                <select
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    formControlName="sapConnectionId" id="sapConnectionId"
                                                    [ngClass]="{ 'is-invalid': formCreateCompany.sapConnectionId.errors && (formCreateCompany.sapConnectionId.touched || formCreateCompany.sapConnectionId.untouched) }">
                                                    <option *ngFor="let conn of sapConnList" [value]="conn.Id">
                                                        {{conn.Id}}
                                                    </option>
                                                </select>
                                                <!-- error block -->
                                                <div class="invalid-feedback"
                                                    *ngIf="formCreateCompany.sapConnectionId.errors">
                                                    <sup>*</sup>Por favor seleccione una conexión
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <label for="Status">Estado de la compañía</label>
                                            <div class="form-group">
                                                <select
                                                    class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                    formControlName="active" id="active"
                                                    [ngClass]="{ 'is-invalid': formCreateCompany.active.errors && (formCreateCompany.active.touched || formCreateCompany.active.untouched) }">
                                                    <option value="true">Activo</option>
                                                    <option value="false">Inactiva</option>
                                                </select>
                                                <!-- error block -->
                                                <div class="invalid-feedback" *ngIf="formCreateCompany.active.errors">
                                                    <sup>*</sup>Por favor digite el nombre de la compañía
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-space">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="id">Nombre del reporte por compañía</label> <br />
                                                <div class="input-group input-group-sm">
                                                    <input style="display: none" type="file"
                                                        (change)="onFileSelected($event,2,2,3)" #inputCompanyReport
                                                        accept=".rpt" />
                                                    <input type="text" class="form-control form-control-sm"
                                                        aria-describedby="btnCompanyReport"
                                                        formControlName="companyReportName" readonly />
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-sm btn-light"
                                                            id="btnCompanyReport" title="Cargar archivo"
                                                            (click)="inputCompanyReport.click()">
                                                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="id">Nombre del reporte por cliente</label> <br />
                                                <div class="input-group input-group-sm">
                                                    <input style="display: none" type="file"
                                                        (change)="onFileSelected($event,2,2,1)" #inputBPReport
                                                        accept=".rpt" />
                                                    <input type="text" class="form-control form-control-sm"
                                                        aria-describedby="btnBPReport"
                                                        formControlName="businessPartnerReportName" readonly />
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-sm btn-light"
                                                            id="btnBPReport" title="Cargar archivo"
                                                            (click)="inputBPReport.click()">
                                                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="id">Nombre del reporte proveedor</label> <br />
                                                <div class="input-group input-group-sm">
                                                    <input style="display: none" type="file"
                                                        (change)="onFileSelected($event,2,2,2)" #inputSuppReport
                                                        accept=".rpt" />
                                                    <input type="text" class="form-control form-control-sm"
                                                        aria-describedby="btnSuppReport"
                                                        formControlName="supplierReportName" readonly />
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-sm btn-light"
                                                            id="btnSuppReport" title="Cargar archivo"
                                                            (click)="inputSuppReport.click()">
                                                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="row row-space">
                                        <div class="col-md-12">
                                            <label for="id">Correo a</label> <br />
                                            <textarea
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                name="emailTo" formControlName="emailTo" id="emailTo"
                                                [ngClass]="{ 'is-invalid': formCreateCompany.emailTo.errors && (formCreateCompany.emailTo.touched || formCreateCompany.emailTo.untouched) }">
                                                                    </textarea>
                                            <!-- error block -->
                                            <div class="invalid-feedback" *ngIf="formCreateCompany.emailTo.errors">
                                                <sup>*</sup>Por favor digite el nombre de la compañía
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="id">Correo copia (separar con punto y coma (;) entre correos
                                                electrónicos)</label> <br />
                                            <textarea name="emailCc" formControlName="emailCc"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="emailCc"></textarea>
                                        </div>
                                        <div class="col-md-12">
                                            <label for="id">Correo interno, envío reporte de clientes (separar con punto
                                                y coma (;) entre correos electrónicos)</label> <br />
                                            <textarea name="InternalEmailReport" formControlName="InternalEmailReport"
                                                class="form-control form-control-sm btn-white no-pm-0 input--style-4"
                                                id="InternalEmailReport">
                                                                                    </textarea>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="id">Firma del correo</label> <br />
                                                <div class="input-group input-group-sm">
                                                    <input style="display: none" type="file"
                                                        (change)="onFileSelected($event,2,1,0)" #inputSearchReport
                                                        accept=".png" />
                                                    <input type="text" class="form-control form-control-sm"
                                                        aria-describedby="btnSearchReport" formControlName="Signature"
                                                        readonly />
                                                    <div class="input-group-append">
                                                        <button type="button" class="btn btn-sm btn-light"
                                                            id="btnSearchReport" title="Cargar archivo"
                                                            (click)="inputSearchReport.click()">
                                                            <i class="fa fa-paperclip" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row row-space pt-2">
                                        <div class="col-md-3">
                                            <button class="form-control" (click)="createCompany()"
                                                [disabled]="!createCompanyForm.valid">
                                                <i class="fa fa-plus"></i>&nbsp;Crear compañía</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="Asignacion de compañía a usuario" *ngIf="HaveAsingCompanyPermission()">
            <ng-template ngbTabContent>
                <div class="asing-company-content">
                    <div fxFlex="100" fxFlex.gt-sm="30">
                        <h2>Usuarios</h2>
                        <input #typeahead id="typeahead-focus" type="text" class="form-control" [(ngModel)]="model"
                            (ngModelChange)="GetSelectedValue()"
                            [ngModelOptions]="{standalone: true}" [ngbTypeahead]="Search"
                            (focus)="focus$.next($any($event).target.value)"
                            (click)="click$.next($any($event).target.value)" #instance="ngbTypeahead" />
                    </div>
                    <div cdkDropListGroup class="optionsContent w-100">
                        <div fxFlex="100" fxFlex.gt-sm="30">
                            <h2>Compañia Asignadas</h2>
                            <div cdkDropList [cdkDropListData]="companiesAssociates" class="cdk-list"
                                (cdkDropListDropped)="Drop($event)">
                                <div class="cdk-box" *ngFor="let company of companiesAssociates" cdkDrag>
                                    {{company.CompanyName}}
                                </div>
                            </div>
                        </div>
                        <div class="buttonColumn" fxFlex="100" fxFlex.gt-sm="10" fxLayout="column" fxLayoutAlign
                            fxLayoutAlign="center center">
                            <button class="buttonArrow" type="button" (click)="SetAllCompany()">
                                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                            </button>
                            <button class="buttonArrow" type="button" mat-button (click)="UnSetAllRoles()">
                                <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div fxFlex="100" fxFlex.gt-sm="30">
                            <h2>Compañia Disponibles</h2>
                            <div cdkDropList [cdkDropListData]="companiesAvailable" class="cdk-list"
                                (cdkDropListDropped)="Drop($event)">
                                <div class="cdk-box" *ngFor="let company of companiesAvailable" cdkDrag>
                                    {{company.CompanyName}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>