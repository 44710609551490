import { Injectable } from '@angular/core';
import { BaseResponse } from '../common/models.index';
import { Observable } from 'rxjs';
import { IRole, AppConstants } from '../models';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IRolesResponse, IRoleResponse, IRolesByUserResponse } from '../models/responses';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private httpClient: HttpClient) { }

  Post(_role: IRole): Observable<BaseResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Role/Post`;

    return this.httpClient.post<BaseResponse>(url, _role, { headers });
  }

  Get(): Observable<IRolesResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Role/Get`;

    return this.httpClient.get<IRolesResponse>(url, { headers });
  }

  GetRole(_idRole: number): Observable<IRoleResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Role/GetRole?id=${_idRole}`;

    return this.httpClient.get<IRoleResponse>(url, { headers });
  }

  Put(_role: IRole): Observable<BaseResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Role/Put`;

    return this.httpClient.post<BaseResponse>(url, _role, { headers });
  }

  GetByUser(_idUser: number): Observable<IRolesByUserResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${environment.ApiUrl}api/Role/GetByUser?id=${_idUser}`;

    return this.httpClient.get<IRolesByUserResponse>(url, { headers });
  }
}
