import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompaniesResponse, AppConstants, ReasingCompanyModel } from '../models';
import { BaseResponse, CompaniaResponse } from '../models/responses';
import { SessionService } from './session.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private http: HttpClient, private sessionService: SessionService) { }

  GetCompaniesByUser(userId: string): Observable<CompaniaResponse>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.sessionService.session.Token}`
    })
    const queryParams = new HttpParams().set("userId", userId)
    return this.http.get<CompaniaResponse>(`${environment.ApiUrl}api/Company/GetCompaniesByUser`, {params: queryParams, headers});
  }

  GetCompaniesNotAssoc(userId: string): Observable<CompaniaResponse>{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.sessionService.session.Token}`
    })
    const queryParams = new HttpParams().set("userId", userId)
    return this.http.get<CompaniaResponse>(`${environment.ApiUrl}api/Company/GetCompaniesNotAssoc`, {params: queryParams, headers});
  }

  ReasingCompany(value: ReasingCompanyModel): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${environment.ApiUrl}api/Company/ReasingCompaniesByUser`, value, { headers: new HttpHeaders({
      'Content-Type':'application/json',
      'Authorization': `Bearer ${this.sessionService.session.Token}`
    })});
  }
}
